import { logger } from '../logging/logger';

/**
 * Fetches the variant of an experiment for a user from Amplitude.
 */
export async function getExperimentVariantOnClient(flagKey: string): Promise<string | null | undefined> {
  try {
    const response = await fetch(`/api/get-client-experiment?flag_key=${flagKey}`);

    if (!response.ok) {
      logger.error(`Not ok returned from /api/get-client-experiment: ${response.status}`, {
        flagKey,
        file: 'src/features/experiments/utils.ts',
        function: 'getExperimentVariantOnClient',
      });
      return null;
    }

    const data: any = await response.json();

    return data?.variationKey || null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Removes the experiment root from the URL pathname.
 */
export function removeExperimentRootFromUrl(url: URL): URL {
  if (!url.pathname.startsWith('/test/')) {
    return url;
  }
  // Example: '/test/albus/variant_a'
  const parts = url.pathname.split('/').slice(4);
  url.pathname = `/${parts.join('/')}`;
  return url;
}
